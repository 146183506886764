<template>
    <div class="product-options">
		<center><h3>Organiza tu Inventario</h3></center>
		<a-card>
			<a-row :gutter="[20, 10]">
				<a-col :span="12">
					<center><img :src="imageInventario" class="card"/></center>		
				</a-col>
				<a-col :span="12">
					<center><img :src="imageInventarioBase" class="card"/></center><br>
					<center><img :src="imageInventarioDetalle" class="card"/></center>
				</a-col>
			</a-row>

			<br>
			<template slot="actions" class="ant-card-actions">
				<a-button
					v-if="$can('existencia.menu.inventario')"
					type="primary" 
					@click="openMenu('inventario')"
					>
					<a-icon type="file-protect" :style="{ fontSize: '20px', fontWeight: '700' }"/> Clic en Inventario
				</a-button>
			</template>
			<a-card-meta title="Opciones de Inventario">
				<span slot="description">
					<a-alert 
						message="Opciones de Inventario y Herramientas:"
						type="info"
						banner
						closable
						>
						<p slot="description">
							<ul>
							<li><span class="note-title">Visualizar Kardex:</span> Consulta el historial detallado de los movimientos de inventario por producto.</li>
							<li><span class="note-title">Editar Precio Costo Unitario:</span> Permite modificar el precio de costo unitario de un producto.</li>
							<li><span class="note-title">Precios de Venta:</span> Gestiona y visualiza los precios de venta de los productos.</li>
							<li><span class="note-title">Nueva Compra:</span> Registra una nueva compra de productos en el inventario.</li>
							</ul>
							<strong>Herramientas:</strong>
							<ul>
							<li><span class="note-title">Generar precio de compra:</span> Calcula el precio de compra basado en los precios de venta existentes.</li>
							<li><span class="note-title">Actualizar Saldos de Inventario:</span> Recalcula y actualiza los saldos de inventario en base a las transacciones recientes.</li>
							<li><span class="note-title">Corregir y Verificar Fechas de Inventario:</span> Verifica y corrige las fechas en las transacciones de inventario.</li>
							<li><span class="note-title">Ajustar Precios de Venta:</span> Realiza ajustes automáticos en los precios de venta.</li>
							<li><span class="note-title">Generar Precios de Venta:</span> Calcula los precios de venta cuando existen precios de compra.</li>
							<li><span class="note-title">Descargar Plantilla Importación:</span> Descarga una plantilla de Excel para la importación de inventario.</li>
							<li><span class="note-title">Importar Inventario Excel:</span> Sube y procesa un archivo Excel para importar productos al inventario.</li>
							<li><span class="note-title">Eliminar Precios de Venta:</span> Elimina precios de venta existentes.</li>
							<li><span class="note-title">Formatear Inventario:</span> Limpia y organiza los datos del inventario.</li>
							</ul>
						</p>
					</a-alert>
				</span>
			</a-card-meta>
		</a-card>
    </div>
</template>

<script>
import router from '@/config/router'

export default {
    data () {
        return {
			imageMasivo: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/sistema/ImportacionMasiva.gif' : '/static/assets/images/sistema/ImportacionMasiva.gif',
			imageInventario: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/sistema/NuevoInventario.gif' : '/static/assets/images/sistema/NuevoInventario.gif',
			imageInventarioBase: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/sistema/NuevoInventarioBase.gif' : '/static/assets/images/sistema/NuevoInventarioBase.gif',
			imageInventarioDetalle: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/sistema/NuevoInventarioDetalle.gif' : '/static/assets/images/sistema/NuevoInventarioDetalle.gif'
		}
    },

	methods: {
		openMenu (name) {
			router.push({ name: name })
		}
	}
}
</script>

<style scoped>
.card {
		border: 2px solid var(--element);
		border-radius: 8px;
		padding: 5px;
		max-width: 100%;
		box-sizing: border-box;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h3 {
  color: #000000;
  font-size: 1.6em;
  font-weight: 700;
  margin-bottom: 20px;
}

.option-description {
  color: #555;
  font-size: 1.1em;
}

ul {
  padding-left: 0px;
}

li {
  margin-bottom: 10px;
}

.note-title {
  font-weight: bold;
  color: #333;
}

.ant-alert-with-description .ant-alert-description {
    display: block;
    padding: 1px 1px 1px 1px !important;
}
</style>