<template>
	<a-card class="welcome-card">
		<a-row justify="center" align="middle" class="header-row">
			<a-col span="23">
				<h3>Bienvenido a efectivo.io</h3>
				<p>Para comenzar te recomendamos estos pasos</p>
			</a-col>
			<a-col span="1" class="close-button">
				<a-button type="link" @click="showCloseModal">
						<a-icon type="close" />
				</a-button>
			</a-col>
		</a-row>
		<a-row justify="space-around" class="steps-container">
			<a-col
				:span="4"
				v-for="(step, index) in steps"
				:key="index"
				:class="['step-col', getColClass(index)]"
				>
				<a-icon :type="step.icon" class="step-icon"/>
				<h4>{{ step.title }}</h4>
				<a-button type="primary" size="small" @click="openModal(step)">{{ step.buttonText }}</a-button>
			</a-col>
		</a-row>
		<a-modal
			v-model="isCloseModalVisible"
			title="Cerrar sección"
			@ok="handleConfirm"
			@cancel="handleCancel"
			>
			<p>Estás por cerrar esta sección</p>
			<p>
			Si ya completaste tus primeros pasos o deseas ocultarlos temporalmente,
			puedes confirmar y seguir a tu ritmo dándole superpoderes a tu negocio
			</p>
			<a-checkbox v-model="doNotShowAgain">No volver a mostrar los primeros pasos</a-checkbox>
		</a-modal>

		<a-modal
			v-model="modalGuia"
			:dialog-style="{ top: '5px' }"
            :width="620"
            :zIndex="1051"
			:title="false"
			:footer="false"
			>
			<template v-if="guia.modal === 'nuevo-producto'">
				<PasoUno />
			</template>

			<template v-if="guia.modal === 'inventario'">
				<PasoInventario />
			</template>

			<template v-if="guia.modal === 'nueva-compra'">
				<PasoCompra />
			</template>

			<template v-if="guia.modal === 'nueva-venta'">
				<PasoVenta />
			</template>

			<template v-if="guia.modal === 'usuario'">
				<PasoUsuario />
			</template>
		</a-modal>
	</a-card>
</template>
	
<script>
import PasoUno from './PasoUno.vue'
import PasoInventario from './PasoInventario.vue'
import PasoCompra from './PasoCompra.vue'
import PasoVenta from './PasoVenta.vue'
import PasoUsuario from './PasoUsuario.vue'

export default {

	components: {
		PasoUno,
		PasoInventario,
		PasoCompra,
		PasoVenta,
		PasoUsuario
	},

	data () {
		return {
			steps: [
				{ icon: 'file', title: 'Paso 1. Crea un nuevo Producto', buttonText: 'Comenzar', modal: 'nuevo-producto' },
				{ icon: 'unordered-list', title: 'Paso 2. Organiza tu Inventario', buttonText: 'Comenzar', modal: 'inventario' },
				{ icon: 'shop', title: 'Paso 3. Realiza Nueva Compra', buttonText: 'Comenzar', modal: 'nueva-compra' },
				{ icon: 'shopping-cart', title: 'Paso 4. Realiza Nueva Venta', buttonText: 'Comenzar', modal: 'nueva-venta' },
				{ icon: 'user', title: 'Paso 5. Administra tus Usuarios', buttonText: 'Comenzar', modal: 'usuario' }
			],
			isCloseModalVisible: false,
			doNotShowAgain: false,
			modalGuia: false,
			guia: {}
		}
	},

	mounted () {
		if (localStorage.getItem('hideWelcomeCard') === 'true') {
			this.$el.style.display = 'none'
		}
	},

	methods: {
		getColClass (index) {
			return `step-col-${index + 1}`
		},

		showCloseModal () {
			this.isCloseModalVisible = true
		},

		handleConfirm () {
			if (this.doNotShowAgain) {
				localStorage.setItem('hideWelcomeCard', 'true')
			}
			this.isCloseModalVisible = false
			this.$el.style.display = 'none'
		},

		handleCancel () {
			this.isCloseModalVisible = false
		},

		openModal (step) {
			this.guia = step
			this.modalGuia = true
		}
	}
}
</script>
	
<style scoped>
	.welcome-card {
		border: 2px solid var(--element);
		border-radius: 8px;
		padding: 5px;
		max-width: 100%;
		box-sizing: border-box;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		margin-bottom: 10px;
	}
	.step-col {
		text-align: center;
		margin-bottom: 1px;
		flex-basis: 100%;
	}
	.step-icon {
		font-size: 35px;
		margin-bottom: 10px;
		color: var(--element);
	}
	.ant-btn-link {
		color: var(--element);
	}
	.header-row {
		margin-bottom: 2px;
	}
	.close-button {
		text-align: right;
	}
	.steps-container {
		display: flex;
		flex-wrap: wrap;
	}
	
	/* Media queries for responsiveness */
	@media (min-width: 576px) {
		.step-col {
			flex-basis: 50%;
		}
	}
	
	@media (min-width: 768px) {
		.step-col {
			flex-basis: 33.33%;
		}
	}
	
	@media (min-width: 1040px) {
		.step-col {
			flex-basis: 20%;
		}
	}
	
	h3,
	p {
		text-align: center;
	}
	
	a-card {
		background-color: #fff;
	}
	
	h4 {
		color: #000;
	}
	
	@media (max-width: 800px) {
		.welcome-card {
			padding: 15px;
		}
		.step-icon {
			font-size: 25px;
		}
		h4 {
			font-size: 14px;
		}
	}
</style>