<template>
    <div>
        <v-chart :options="chartOptions" autoresize style="width:100% !important;"/>
    </div>
</template>
  
<script>
    import ECharts from 'vue-echarts/components/ECharts.vue'
    import 'echarts/lib/chart/bar'
    import 'echarts/lib/component/tooltip'
    import 'echarts/lib/component/title'
    import 'echarts/lib/component/grid'
    import 'echarts/lib/component/legend'

    export default {
        components: {
            'v-chart': ECharts
        },

        props: {
            data: {
                type: Object,
                required: true
            }
        },
        
        computed: {
            chartOptions () {
                return {
                    color: this.data.colors,
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        data: this.data.labels
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: this.data.lista.map(item => item.date),
                            axisTick: {
                            alignWithLabel: true
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    series: [
                        {
                            name: this.data.labels[0],
                            type: 'bar',
                            data: this.data.lista.map(item => parseFloat(item.venta))
                        },
                        {
                            name: this.data.labels[1],
                            type: 'bar',
                            data: this.data.lista.map(item => parseFloat(item.compra))
                        }
                    ]
                }
            }
        }
    }
 </script>