<template>
    <div class="product-options">
		<center><h3>Realizar registro de Usuarios</h3></center>
		<a-card>
			<a-row :gutter="[20, 10]">
				<a-col :span="12">
					<center><img :src="imageUsuario" class="card"/></center>		
				</a-col>
				<a-col :span="12">
					<center><img :src="imageUsuarioBase" class="card"/></center><br>
					<center><img :src="imageUsuarioDetalle" class="card"/></center>
				</a-col>
			</a-row>

			<br>
			<template slot="actions" class="ant-card-actions">
				<a-button
					type="primary" 
					@click="openMenu('usuario')" 
					v-if="$can('manager.menu.usuario')"
					> 
					<a-icon type="user" :style="{ fontSize: '20px', fontWeight: '700' }"/> Usuarios
				</a-button>
			</template>
			<a-card-meta title="Ingresa a las opciones">
				<span slot="description">
					Podras consultar y registrar Usuarios
				</span>
			</a-card-meta>
		</a-card>
    </div>
</template>

<script>
import router from '@/config/router'

export default {
    data () {
        return {
			imageUsuario: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/sistema/NuevoUsuario.gif' : '/static/assets/images/sistema/NuevoUsuario.gif',
			imageUsuarioBase: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/sistema/NuevoUsuarioBase.gif' : '/static/assets/images/sistema/NuevoUsuarioBase.gif',
			imageUsuarioDetalle: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/sistema/NuevoUsuarioDetalle.gif' : '/static/assets/images/sistema/NuevoUsuarioDetalle.gif'
		}
    },

	methods: {
		openMenu (name) {
			router.push({ name: name })
		},

		openMenuQuery (name, query) {
			this.$router.push({
				name: name,
				params: { ventanaID: query }
			})
		}
	}
}
</script>

<style scoped>
.card {
		border: 2px solid var(--element);
		border-radius: 8px;
		padding: 5px;
		max-width: 100%;
		box-sizing: border-box;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h3 {
  color: #000000;
  font-size: 1.6em;
  font-weight: 700;
  margin-bottom: 20px;
}

.option-description {
  color: #555;
  font-size: 1.1em;
}

ul {
  padding-left: 0px;
}

li {
  margin-bottom: 10px;
}

.note-title {
  font-weight: bold;
  color: #333;
}

.ant-alert-with-description .ant-alert-description {
    display: block;
    padding: 1px 1px 1px 1px !important;
}
</style>