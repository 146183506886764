<template>
    <div class="product-options">
		<center><h3>Tienes dos opciones para agregar productos</h3></center>
		<a-card>
			<a-row :gutter="[20, 10]">
				<a-col :span="12">
					<center><img :src="imageProducto" class="card"/></center>		
				</a-col>
				<a-col :span="12">
					<center><img :src="imageProductoBase" class="card"/></center><br>
					<center><img :src="imageProductoHeader" class="card"/></center>
				</a-col>
			</a-row>

			<br>
			<template slot="actions" class="ant-card-actions">
				<a-button 
					v-if="$can('registro.menu.producto')"
					type="primary"
					@click="openMenu('catalogo')"
					> 
					<a-icon type="shop" :style="{ fontSize: '20px', fontWeight: '700' }"/> Ingresar a Producto
				</a-button>
			</template>
			<a-card-meta title="Opción 1: Crear un nuevo producto manualmente">
				<span slot="description">
					<span>Puedes crear un nuevo producto manualmente haciendo clic en el botón "Crear Nuevo Producto".</span><br><br>
				</span>
			</a-card-meta>
		</a-card>

		<br><br>

		<a-card>
			<center><img :src="imageMasivo" class="card"/></center><br>
			<a-card-meta title="Opción 2: Importación masiva mediante un archivo Excel.">
				<span slot="description">
					<span>También puedes utilizar la importación masiva mediante un archivo Excel.</span><br><br>
					<a-alert 
						message="Notas importantes al usar la plantilla de Excel:" 
						type="warning"
						banner
						closable
						>
						<p slot="description">
							<ul>
							<li><span class="note-title">Proveedor:</span> No debe estar vacío (el nombre del proveedor).</li>
							<li><span class="note-title">Código:</span> No debe estar vacío, debe contener solo números (código de barras).</li>
							<li><span class="note-title">Nombre:</span> No debe estar vacío, debe ser una cadena de caracteres.</li>
							<li><span class="note-title">Medida:</span> No debe estar vacío, debe ser una cadena de caracteres.</li>
							<li><span class="note-title">Unidad:</span> No debe estar vacío, debe ser una cadena de caracteres.</li>
							<li><span class="note-title">Categoría:</span> No debe estar vacío, debe ser una cadena de caracteres.</li>
							<li><span class="note-title">Marca:</span> No debe estar vacío, debe ser una cadena de caracteres.</li>
							<li><span class="note-title">Cantidad:</span> No debe estar vacío, solo números y decimales.</li>
							<li><span class="note-title">P.Compra:</span> No debe estar vacío, solo números y decimales.</li>
							<li><span class="note-title">P.Venta:</span> No debe estar vacío, solo números y decimales.</li>
							</ul>
							<strong>Nota:</strong> En el archivo de Excel, asegúrate de que todo el texto esté sin formato
						</p>
					</a-alert>
				</span>
			</a-card-meta>
		</a-card>
    </div>
</template>

<script>
import router from '@/config/router'

export default {
    data () {
        return {
			imageMasivo: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/sistema/ImportacionMasiva.gif' : '/static/assets/images/sistema/ImportacionMasiva.gif',
			imageProducto: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/sistema/NuevoProducto.gif' : '/static/assets/images/sistema/NuevoProducto.gif',
			imageProductoBase: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/sistema/NuevoProductoBase.gif' : '/static/assets/images/sistema/NuevoProductoBase.gif',
			imageProductoHeader: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/sistema/NuevoProductoHeader.gif' : '/static/assets/images/sistema/NuevoProductoHeader.gif'
		}
    },

	methods: {
		openMenu (name) {
			router.push({ name: name })
		}
	}
}
</script>

<style scoped>
.card {
		border: 2px solid var(--element);
		border-radius: 8px;
		padding: 5px;
		max-width: 100%;
		box-sizing: border-box;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h3 {
  color: #000000;
  font-size: 1.6em;
  font-weight: 700;
  margin-bottom: 20px;
}

.option-description {
  color: #555;
  font-size: 1.1em;
}

ul {
  padding-left: 0px;
}

li {
  margin-bottom: 10px;
}

.note-title {
  font-weight: bold;
  color: #333;
}

.ant-alert-with-description .ant-alert-description {
    display: block;
    padding: 1px 1px 1px 1px !important;
}
</style>