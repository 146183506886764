<template>
    <div class="business-summary">
        <a-row type="flex" :gutter="[5, 10]" justify="center" v-if="$can('desktop.menu.escritorio.de.administrador')">
			<a-col flex="1 1 200px" v-for="(item, index) in reportData" :key="index">
				<a-card hoverable :style="cardStyle">
					<a-row type="flex">
					<a-col flex="1 1 5px">
						<img :src="item.image" class="img-responsive" style="width: 70px; height: 70px; margin: 8px;" />
					</a-col>
					<a-col flex="1 1 100px">
						<div>
						<span class="title">{{ item.title }}</span>
						<br>
						<span class="subtitle">{{ item.tipo }}</span>
						<br>
						<span class="amount">{{ item.value }}</span>
						<br>
                        <a-button type="link" size="small" @click="item.action" :style="{ fontWeight: '700' }">Ingresar</a-button>
						</div>
					</a-col>
					</a-row>
				</a-card>
			</a-col>
		</a-row>
    </div>
</template>
  
<script>
import router from '@/config/router'
import { mapActions, mapGetters } from 'vuex'
import * as utils from '@/utils/utils'

export default {
    data () {
        return {
			utils,
			imageEconomy: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/menu/economy.png' : '/static/assets/images/menu/economy.png',
			imagePerformance: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/menu/sales_performance.svg' : '/static/assets/images/menu/sales_performance.svg',
			imageBuyer: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/menu/buyer.png' : '/static/assets/images/menu/buyer.png',
			imageBill: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/menu/bill.png' : '/static/assets/images/menu/bill.png',
			imageFeature: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/menu/feature.png' : '/static/assets/images/menu/feature.png',
			spinnerloading: false,
			loading: true,
			cardStyle: {
				border: '2px solid #a3a3a3',
                borderRadius: '8px',
                padding: '5px',
                maxWidth: '100%',
                boxSizing: 'border-box',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
			},
            reportData: []
		}
    },

    mounted () {
		this.fetchReportData()
	},

    computed: {
		...mapGetters('auth', ['user']),
        ...mapGetters('configuracion', ['config']),
        ...mapGetters('estadistica', ['reportemensual'])
	},
	
	methods: {
        ...mapActions('estadistica', ['getReporteMensual']),

		fetchReportData () {
			this.getReporteMensual()
			.then(response => {
                const report = this.reportemensual
				this.reportData = [
					{
						title: 'Ventas',
						tipo: report.tipo,
						value: report.venta,
						image: this.imageEconomy,
						action: () => this.openMenu('consultarsalida')
					},
					{
						title: 'Credito',
						tipo: report.tipo,
						value: report.ventaCredito,
						image: this.imagePerformance,
						action: () => this.openMenu('consultarsalida')
					},
					{
						title: 'Compras',
						tipo: report.tipo,
						value: report.compra,
						image: this.imageBuyer,
						action: () => this.openMenu('consultacompra')
					},
					{
						title: 'Gastos',
						tipo: report.tipo,
						value: report.gasto,
						image: this.imageBill,
						action: () => this.openMenu('arqueocaja')
					},
					{
						title: 'Productos',
						tipo: 'Cantidad',
						value: report.producto,
						image: this.imageFeature,
						action: () => this.openMenu('catalogo')
					}
				]
				this.spinnerloading = false
            }).catch(error => {
                this.loading = false
                utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
            })
		},

        openMenu (name) {
			router.push({ name: name })
		},

		openMenuQuery (name, query) {
			this.$router.push({
				name: name,
				params: { ventanaID: query }
			})
		}
	}
}
</script>
  
<style>
.business-summary {
    background-color: #f0f2f5;
}

.ant-card-body {
	padding: 1px;
	zoom: 1;
}

.title {
	font-size: 14px;
	font-weight: bold;
	color: #333;
}

.subtitle {
	color: #979797;
}

.amount {
	font-weight: 500;
	font-size: 15px;
	color: var(--element);
}
</style>