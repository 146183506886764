<template>
	<div>
		<BienvenidoCard />

		<BusinessSummary />

		<a-row justify="space-around" type="flex" :gutter="[10, 10]" style="overflow-y: auto;">
				<a-col flex="1 1 300px">
					<a-row type="flex">
						<a-col flex="1 1 100px" style="margin-top:30px;">
							<a-divider orientation="left">
								<strong>Diario Venta {{ reportemensual.fechaActual }}</strong>
							</a-divider>

							<a-row type="flex">
								<a-col flex="1 1 200px" :offset="1">
									<a-statistic 
										title="Venta Total Diario" 
										:value="utils.formatMoney(reportediariousuario.ventaContado, 2)"
										:value-style="{ color: 'var(--element)', fontSize: '35px', fontWeight: '600' }"
										>
										<template #suffix>
											&nbsp;{{ config.monedasimbolo }} &nbsp;&nbsp;<a-icon type="arrow-up" />
										</template>
									</a-statistic>
								</a-col>
								<a-col flex="1 1 200px" :offset="1">
									<a-statistic 
										title="Gasto Total Diario" 
										:value="0" 
										:value-style="{ color: '#585858', fontSize: '35px', fontWeight: '600' }"
										>
										<template #suffix>
											&nbsp;{{ config.monedasimbolo }} &nbsp;&nbsp;<a-icon type="arrow-down" />
										</template>
									</a-statistic>
								</a-col>
							</a-row>
						</a-col>

						<a-col flex="1 1 300px">
							<a-divider orientation="left">
								<strong>Ultimo Inicio de Sesion</strong>
							</a-divider>
							<table width="100%" border="1">
								<tr>
									<td style="font-size:13px; font-weight:700; width:30%;">Usuario: </td>
									<td class="info-box-text-indicador" style="font-size:14px;">{{ resumenlogin.usuario }}</td>
								</tr>
								<tr>
									<td style="font-size:13px; font-weight:700; width:30%;">Direccion IP: </td>
									<td colspan="3" class="info-box-text-indicador" style="font-size:14px;">{{ resumenlogin.remote_addr }}</td>
								</tr>
								<tr>
									<td style="font-size:13px; font-weight:700; width:30%;">Dispositivo: </td>
									<td colspan="3" class="info-box-text-indicador" style="font-size:14px;">{{ resumenlogin.http_user_agent }}</td>
								</tr>
								<tr>
									<td style="font-size:13px; font-weight:700; width:30%;">Ultima Conexion: </td>
									<td colspan="3" class="info-box-text-indicador" style="font-size:14px;">{{ resumenlogin.tiempo }}  {{ resumenlogin.created_ats }}</td>
								</tr>
							</table><br>
						</a-col>
					</a-row>
				</a-col>
		</a-row>

		<a-row justify="space-around">
			<a-col :span="24">
				<a-card 
					title="Reporte gráfico de Ventas y Compras" 
					v-if="$can('desktop.menu.escritorio.de.administrador')"
					>
					<center>
						<a-spin :spinning="loading">
							<template v-if="loading === false">
								<BarChart
									:data="reportegrafico"
								/>
							</template>
						</a-spin>
					</center>
				</a-card>
			</a-col>
		</a-row>
	</div>
</template>

<script>

import router from '@/config/router'
import { mapActions, mapGetters } from 'vuex'
import BarChart from '../../charts/BarChart.vue'
import * as utils from '@/utils/utils'
import BienvenidoCard from './components/BienvenidoCard.vue'
import BusinessSummary from './components/BusinessSummary.vue'

export default {
	
    components: {
		BarChart,
		BienvenidoCard,
		BusinessSummary
	},

	data () {
		return {
			utils,
			consulta: {
				usuarioID: ''
			},
			loading: true
		}
	},

	mounted () {
		this.getResumenLogin()
		this.getReporteDiarioUsuario()
		setTimeout(() => {
			this.listaReporteGrafico()
    	}, 3000)
	},

    computed: {
		...mapGetters('auth', ['user']),
		...mapGetters('seguridad', ['resumenlogin']),
        ...mapGetters('configuracion', ['config']),
        ...mapGetters('estadistica', ['reportemensual', 'reportegrafico', 'reportediariousuario'])
	},
	
	methods: {
		...mapActions('seguridad', ['getResumenLogin']),
        ...mapActions('estadistica', ['getReporteGrafico', 'getReporteDiarioUsuario']),

		listaReporteGrafico () {
			this.loading = true
			this.consulta.usuarioID = (this.$can('desktop.menu.escritorio.de.administrador')) ? '' : this.user.usuario_id
			this.getReporteGrafico(this.consulta)
			.then(response => {
				this.spinnerloading = true
				this.loading = false
            }).catch(error => {
                this.loading = false
                utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
            })
		}
	}
}
</script>

<style scoped>
    table {
        border-collapse: collapse; /* Para evitar espacios entre las celdas */
    }

    table, th, td {
        border: 0px solid #8d8d8d; /* Cambia el color a tu preferencia */
    }

</style>